<!-- eslint-disable max-lines-per-function -->
<template>
  <credit-card-registration
    :card-info="creditCard"
    :billing-address="billingAddress"
    :states="statesOptions.billing"
    :cities="citiesOptions.billing"
    :countries="countries"
    :is-loading="isRegisteringCard"
    @change:card="onChangeCard"
    @change:address="onChangeAddress"
    @click:save="onSaveCard"
    @back="$router.push({ name: 'wallet-payment' })"
  ></credit-card-registration>
</template>

<script setup>
import find from "lodash.find"
import { computed, onBeforeUnmount, onMounted } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers"

import { useStoreAction } from "@/composables"
import i18n from "@/i18n"

import CreditCardRegistration from "./components/CreditCardRegistration.vue"

const router = useRouter()
const { sendNotification } = useNamespacedActions("notifications", [
  "sendNotification",
])
const { countries, statesOptions, citiesOptions } = useNamespacedState(
  "parameters",
  ["statesOptions", "citiesOptions", "countries"]
)

const { guest } = useNamespacedState("guest", ["guest"])
const { billingAddress, creditCard } = useNamespacedState("warranty/payment", [
  "billingAddress",
  "creditCard",
  "order",
])
const { changeBillingAddress, changeCreditCardInfo } = useNamespacedMutations(
  "warranty/payment",
  ["changeBillingAddress", "changeCreditCardInfo"]
)

const { tryFillByZipCode } = useStoreAction(
  "warranty/payment",
  "tryFillByZipCode"
)

const {
  registerCard,
  isLoading: isRegisteringCard,
  hasError: hasErrorRegisteringCard,
} = useStoreAction("warranty/payment", "registerCard")

const { getStates } = useStoreAction("parameters", ["getStates"])
const { getCities } = useStoreAction("parameters", ["getCities"])

let isBillingAddressDirty = computed(() => {
  return (
    billingAddress.value.zipCode ||
    billingAddress.value.address ||
    billingAddress.value.neighborhood ||
    billingAddress.value.cityId ||
    billingAddress.value.stateId ||
    billingAddress.value.countryId
  )
})

const onChangeAddress = (payload) => {
  changeBillingAddress(payload)

  if (billingAddress.value.countryId === "BR") {
    if ("zipCode" in payload && payload.zipCode.length === 0) {
      resetBillingAddress()
    }

    if ("zipCode" in payload && payload.zipCode.length === 9) {
      tryFillByZipCode()
    }
  }

  if ("countryId" in payload) onChangeCountry(payload.countryId)
  if ("stateId" in payload) onChangeState(payload.stateId)
  if ("cityId" in payload) onChangeCity(payload.cityId)
}

const onChangeCard = (payload) => {
  changeCreditCardInfo(payload)
}

const onChangeCountry = (countryId) => {
  changeBillingAddress({
    stateName: "",
    stateId: "",
    cityName: "",
    cityId: "",
    neighborhood: "",
    address: "",
    zipCode: "",
  })
  getStates({ section: "billing", countryCode: countryId })
}

const onChangeState = (stateId) => {
  const state = find(statesOptions.value.residence, { id: stateId })

  changeBillingAddress({
    stateName: state?.name ?? "",
    stateId,
    cityId: "",
    cityName: "",
  })

  getCities({
    stateId,
    section: "billing",
  })
}

const onChangeCity = (cityId) => {
  const city = find(citiesOptions.value.residence, { id: cityId })

  changeBillingAddress({
    cityId,
    cityName: city?.name ?? "",
  })
}

const onSaveCard = async () => {
  await registerCard()

  if (hasErrorRegisteringCard.value) {
    sendNotification({
      message: i18n.tc("error.internal_error_description"),
      duration: 5000,
      confirmationText: "Ok",
      color: "var(--orange-500)",
    })
  } else {
    router.push({ name: "wallet-payment" })
  }
}

const fillBillingAddressWithGuestInfo = () => {
  let fields = [
    "zipCode",
    "address",
    "addressNumber",
    "addressComplement",
    "neighborhood",
    "cityId",
    "cityName",
    "stateName",
    "stateId",
    "countryId",
    "email",
  ]

  if (guest.value.countryId && guest.value.stateId) {
    getStates({ countryCode: guest.value.countryId, section: "billing" })
    getCities({ stateId: guest.value.stateId, section: "billing" })
  }

  for (let field of fields) {
    changeBillingAddress({ [field]: guest.value[field] || "" })
  }
}

const resetBillingAddress = () => {
  changeBillingAddress({
    zipCode: "",
    address: "",
    addressNumber: "",
    addressComplement: "",
    neighborhood: "",
    cityId: "",
    cityName: "",
    stateName: "",
    stateId: "",
    countryId: "",
  })
}

onMounted(() => {
  if (!isBillingAddressDirty.value) {
    fillBillingAddressWithGuestInfo()
  }
})

onBeforeUnmount(() => resetBillingAddress())
</script>
